import request from '@/utils/request'

// 获取session_id
export const getSessionId = () => {
  return request({
    url: '/user/get_session_id'
  })
}

// 登录
export const userLogin = data => {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

// 注册
export const userRegister = data => {
  return request({
    url: '/user/register',
    method: 'post',
    data
  })
}
